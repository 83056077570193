@import "./colors.scss";
.ui.container.component--locations {
  display: flex;
  flex-direction: column;
  .table-row--selected {
    background: var(--main-bg-color);
  }
  .table-row--selected:hover {
    background: grey;
  }

  .row--full {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup {
    background: white;
    padding: 10px;
  }
}
