@import "./colors.scss";
$borderRadius: 1em;

.temp-popup-content {
  .ui.grid {
    .row {
      margin: 0;
      padding: 0;
    }
  }
}
ul.ui.list.unstyled {
  list-style: none;
}
.sharing-points-index-screen .ui.search input {
  border-radius: $borderRadius;
}
.badge {
  background: $primaryColor;
  border-radius: 50%;
  padding: 0.1em;
  width: 1.4em;
  height: 1.4em;
  margin-left: 0.5em;
  font-size: 1.2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $white;
  font-size: 600;
}
// .error {
//   border: solid 2px lighten(red, 20%);
//   border-radius: 5px;
//   background: lighten(red, 40%);
// }

div.icon-wrapper {
  margin-right: 3em;
}

// style scrollbar
::-webkit-scrollbar {
  width: 0.5em !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px $primaryColor(0, 0, 0, 0.3);
  background: $lightGrey !important;
}

::-webkit-scrollbar-thumb {
  background: $primaryColor !important;
}

.ui.grid .column.unpadded {
  padding-top: 2px;
  padding-bottom: 10px;
}
.screen--sharingpoints {
  // margin-top: 75px;
  margin: auto;
  //width: 1400px;
  display: flex;
  // height: calc(100vh - 5em);
  position: relative;
  .flex {
    display: flex;
    justify-content: flex-start;
  }
  .wrapper {
    height: 100%;
    position: relative;
  }
  .router-wrapper {
    min-height: calc(100%);
    padding: 0px;
    width: 100%;
    padding: 0px;
    .screen {
      position: relative;
    }
    .swap-screen {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      background: $white;
      border-radius: 10px;
      color: #222;
      .swap-component {
        height: 100%;
        & > .ui.grid {
          margin: 0;
        }
        .content {
          height: 100%;
          .outer-table {
            width: 100%;
            height: 100%;
            padding: 0 0px;
            margin: 0px;
            .table {
              .tbody {
                .tr {
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    left: 1%;
                    right: 1%;
                    bottom: 0;
                    border-bottom: solid 1px $borderColor;
                  }
                  .td {
                    display: flex !important;
                    height: 100%;
                    align-items: center;
                    border: none;
                    &:first-child {
                      padding-left: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .detail-wrapper {
          height: 100%;
          .swap-detail {
            height: 100%;
            .segment {
              margin-left: -10px;
              height: 100%;
              .content {
                height: 100%;
                overflow-x: hidden;
                .ui.grid {
                  margin: 0;
                  .column {
                    padding: 0;
                  }
                }
                .ui.grid.summary {
                  margin: 2em 0;
                }
                .ui.grid.in-and-out {
                  margin: 2em 0;
                  .ui.grid {
                    padding: 0.2em 0;
                  }
                  .flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 50%;
                    .badge {
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sharing-points-index-screen {
      height: 100% !important;
      display: block;
      position: relative;
      padding: 0px 0px;
      .sharing-points-index {
        height: calc(100%);
        padding: 10px 0px;
        .table-title {
          font-weight: 400;
        }
        .outer-table {
          overflow-y: hidden;
          height: 100%;
          position: relative;
          padding: 20px;
          background: #fff;
          color: #333;
          border-radius: $borderRadius;
          a.link {
            color: #222;
            font-weight: 600;
          }
          .tbody {
            .tr {
              box-shadow: none;
              &:hover {
                .detail-link {
                  .ui.button {
                    &:before {
                      content: "details";
                      flex: 1;
                      flex-direction: row;
                      align-self: center;
                      display: flex;
                    }
                  }
                }
              }
              .td {
                box-shadow: none;
                display: flex !important;
                flex-direction: row;
                //align-items: center;
                * {
                  align-self: center;
                  //height: 100%;
                }
              }
              .td:first-child {
                padding-left: 1.5em;
              }
              .td:last-child {
                padding-right: 1.5em;
              }
              .detail-link {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                a.ui.button {
                  flex: 1;
                }
                &:before {
                  content: "";
                  width: 50px;
                  flex: 1;
                  display: flex;
                }
                div.text {
                  flex: 1;
                }
              }
              a.ui.button.link {
                //color: $primaryColor;
                background: none;
                flex: none;
                display: flex;
              }
              &.selected {
                .td {
                  background: $primaryColor;
                  color: $white;
                  a {
                    color: $white;
                  }
                }
                .td:first-child {
                  border-radius: $borderRadius 0 0 $borderRadius;
                }
                .td:last-child {
                  border-radius: 0 $borderRadius $borderRadius 0;
                  a.ui.button.link {
                    color: $primaryColor;
                    background: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .screen.screen--sharingpoints-show {
      display: flex;
      // height: 100%;
      width: 100%;
      padding: 10px 0;
      
      flex-direction: column;

      .sub-nav-wrapper {
        //height: 40px;
        padding: 10px;
        margin-bottom: 10px;
        // padding: 5px 0;
        width: 100%;
        background: #fff;
        border-radius: 10px;
        justify-content: flex-start;
        nav.sub-nav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          font-size: 1.2em;
          font-weight: 600;
          a {
            color: $enyringPrimaryColor;
            padding: 5px;
            &.active {
              background-color: $enyringPrimaryColor;
              padding: 5px;
              border-radius: 5px;
              color: #fff;
            }
            &:hover {
              background-color: $enyringPrimaryColor;
              padding: 5px;
              border-radius: 5px;
              color: #fff;
            }
          }
        }
      }
      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        * {
          flex: 1;
        }
      }
      .flex.content {
        width: 100%;
        
        .sub-router {
          // min-height: 1000px;
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          background: $white;
          border-radius: 1em;
          padding: 10px;
          box-sizing: border-box;

          .map-screen {
            height: 100%;
            width: 100%;

            .wrapper.wrapper--map {
              height: 100%;
            }
          }
          .error-history-screen {
            height: 95%;
            width: 99%;
            margin: auto;
            color: #333;
            .error-history {
              height: 100%;
              & > .header {
                margin: 5px 10px;
              }
              .content {
                height: 100%;
                overflow-y: hidden;
                .outer-table {
                  height: 100%;
                  width: calc(100% - 4px);
                  margin: 2px;
                  overflow-y: hidden;
                  overflow-x: hidden;
                  .table-header {
                    .tr {
                      width: 100% !important;
                    }
                    .tr:last-child {
                      .th {
                        .ui.two.column.grid {
                          .column:first-child {
                            padding-left: 15px;
                            text-align: left;
                          }
                        }
                      }
                    }
                  }
                  .tbody {
                    .tr {
                      position: relative;
                      &:before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 2%;
                        right: 2%;
                        border-bottom: 1px solid #ccc;
                      }
                      .td {
                        border: none;
                      }
                    }
                  }
                }
              }
              & > .row {
                height: 100%;
                & > .column {
                  height: 100%;
                  & > .ui.container {
                    height: 100%;
                  }
                }
              }
            }
          }
          .files-and-notes-screen {
            padding-left: 50px;
            flex-direction: column;
            & > .ui.grid {
              width: 100%;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 0;
              & > .row {
                flex: none;
              }
              .ui.card {
                .card-header {
                  //padding: 0;
                  .right.column {
                    padding: 0;
                  }
                }
                .ui.comments {
                  display: flex;
                  .ui.segment {
                    width: 50%;
                    margin: 5px;
                    flex: 1;
                    .comment {
                      .content {
                        display: flex;
                        flex-direction: column;
                        * {
                          color: $primaryColor2;
                        }
                        .text {
                          margin: 10px 0;
                          border: solid $primaryColor2 1px;
                          padding: 5px;
                          border-radius: $borderRadius;
                        }
                        a {
                          float: right;
                          .ui.two.buttons {
                            width: 100px;
                            * {
                              color: #fff !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .location-detail-screen {
            height: 100%;
            & > .ui.grid {
              height: 100%;
              padding: 0;
              margin: 0;
              & > .column {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              .ui.grid {
                .row,
                .column {
                  padding-top: 2px;
                  padding-bottom: 3px;
                }
              }
            }
            * {
              color: #333;
            }
          }
          .interactions-screen {
            .ui.card.fluid.interactions {
              .table {
                .table-header {
                  .tr:nth-child(2) {
                    .th {
                      //padding: 0 10px;
                      //background: red;
                      & > div {
                        display: flex;
                        justify-content: center;
                      }
                    }
                  }
                }
              }
            }
          }
          .charts-screen {
            height: 100%;
            .ui.card.chart {
              & > .header {
                padding: 5px 10px;
              }
              .content {
                .chart__toolbar {
                  .dates-select {
                    .flex {
                      display: flex;
                      & > div {
                        flex: 1;
                      }
                    }
                    .radio-wrapper {
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }
          .files-and-notes-screen,
          .screen--settings {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            padding: 0 10px 0 0;
            padding-right: 0 !important;
            .ui.card {
              margin-top: 0.5em;
              & > .header {
                padding: 10px;
                text-align: left;
              }
            }
          }
        }
        .ui.container.show-content {
          height: 65%;
          .show-index {
            display: flex;
            height: 100%;
          }
        }
        .aside {
          margin-top: 10px;
          height: 100%;
          position: relative;
          width: 30%;
          flex: 1;
          padding: 0 0 0 10px;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;

          & > div {
            flex: 1 0;
            //align-self: stretch;
          }
        }
      }
    }
  }
}
.ui.modal.qr-code-modal {
  width: 340px;
  height: 340px;
  padding: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ui.grid.errors-list {
  .ui.grid {
    .column {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }
}
.ui.grid .column.unpadded {
  padding-top: 2px;
  padding-bottom: 2px;
}
.row.screen-header {
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  .column {
    vertical-align: middle;
  }
}
table.ui.table tbody tr.table-row td:last-child {
  text-align: left;
}
.screen--sharingpoints--edit,
.screen--sharingpoints--create {
  * {
    color: #333;
  }
  .ui.card {
    .ui.form {
      & > .header {
        padding: 1em;
        border-bottom: solid 1px $borderColor;
      }
      & > .content {
        padding: 1em;
        .row {
          text-align: left;
          margin: 1em 0;
        }
      }
      & > .meta {
        padding: 1em;
        border-top: solid 1px #ddd;
        .button {
          &--cancel {
            background: $borderColor;
          }
          &--save {
            background: $primaryColor;
            *,
            & {
              color: $white;
            }
          }
        }
      }
    }
  }
}

// below will be outdated soon

.screen--sharingpoints--settings {
  color: #333;
  .ui.card {
  }
  form {
    .ui.grid {
      span.min,
      span.max,
      span.unit {
        margin: 0 5px 0 0;
      }
    }
    .ui.card.sp-box {
      &--power-module,
      &--thermal-module {
        .sp-box__header {
          .info-section {
            display: flex;

            justify-content: flex-start !important;
          }
        }
      }

      &--charge-module .header {
      }
      .box-item {
        i.window {
          color: #999;
        }
      }
      .sp-box__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > * {
          font-size: 1.5em;
          font-weight: bold;
          margin-left: 0;
        }
        .box-number-wrapper {
          background: #ccc;
          display: flex;
          align-items: center;
          width: 40px;
          .ui.label.box-number {
            font-size: 20px;
            background: none;
            vertical-align: middle;
            font-weight: bold;
            margin-left: 0;
          }
        }
        .box-sub-type {
          margin: 0 20px 0 0;
          min-width: 120px;
          max-width: 200px;
        }
        .standard {
          flex: 1;
          max-width: 20%;
          max-width: 200px;
          display: flex;
          .door-state-wrapper {
            margin: 5px;
            width: 40px;
            i.door-state {
            }
          }
        }
        .info-section {
          flex: 1;
          width: 70% !important;
          justify-content: space-between;
          align-items: center;
          display: flex;
          .left-section {
            width: 100%;
            display: flex;
            align-items: center;
            .icons-wrapper {
              display: flex;
              width: 40%;
              justify-content: space-between;
              align-items: center;
            }
          }
          .slot-wrapper {
            flex: 1;
            margin-right: 0em !important;
          }
        }
        .actions {
          //flex: 1;
          min-width: 10%;
          display: flex;
          justify-content: flex-end;
          width: 2em !important;
          margin-right: 1em !important;
        }
        .ui.button.expand-button {
          height: 100%;
          font-size: none;
          margin: 0;
        }
      }
    }
    .box-type {
      display: inline-block;
      margin: 15px 0;
      vertical-align: middle;
      &--short {
        background-color: #ccc;
        border-radius: $borderRadius;
      }
    }

    .content.content--page {
      height: 100%;
    }
    .component--sharingpoints {
      height: 100%;
      .index-wrapper {
        height: 100%;
        .ui.grid.vertical.sharingpoints-container {
          overflow: hidden;
          height: 100%;
          padding-top: 0px;
          .column.table-wrapper {
            height: 100%;
            overflow-y: scroll;
          }
          .column.map-wrapper {
            padding-top: 20px;
          }
        }
      }
    }
  }
  .table-wrapper {
    img.img--batterie {
      width: 30px;
    }
  }
}

.interactions-filter {
  .ui.input {
    width: 100%;
    margin-bottom: 0.6em;
  }
  .ui.dropdown {
    width: 100%;
    margin-bottom: 0.6em;
  }
}

.ui.toggle.checkbox.fitted .box:before,
.ui.toggle.checkbox.fitted:focus .box:before,
.ui.toggle.checkbox:active .box:before,
.ui.toggle.checkbox label:before {
  // background: lighten(red, 20%) !important;
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ label:before {
  // background: lighten(green, 5%) !important;
}
.ui.modals.dimmer.visible.active {
  //background: #e1e1e1;
  //opacity: 0.5;
  // backdrop-filter: blur(1px);

  background-color: rgba(12, 12, 12, 0.5);
  .ui.modal {
    opacity: 1;
    background: white;
    border-radius: $borderRadius;
    .actions {
      padding: 10px 14px 14px 0 !important;
      text-align: right !important;
    }
  }
  .ui.modal > .close {
    top: 5px;
    right: 5px;
    color: #444;
  }
}

.ui.button {
  &.primary {
    background: $primaryColor;
    &:hover {
      background: darken($primaryColor, 10%);
    }
  }
  &.secondary {
    background: #ddd;
    &:focus,
    &:hover,
    &:active,
    &:visited {
      background: #bbb;
    }
  }
}

.screen--settings {
  .settings-form {
    .ui.button.secondary {
      margin-right: 10px;
    }
    &.is-disabled {
      &:hover {
        .ui.button.secondary {
          box-shadow: 2px 2px 2px green inset, -2px -2px 2px green inset;
          color: green;
        }
      }
    }
  }
  span.unit {
    padding-left: 4px;
  }
  .ui.grid.temperature-segment {
    margin-top: 10px;
  }
  .card--boxes {
    .ui.card.sp-box {
      .divider {
        margin: 1rem 0 2rem 0;
      }

      & > .content {
        padding-top: 2.5rem;
      }
      .slot-wrapper {
        text-align: center;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        .battery-slots {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          display: flex;
          flex-wrap: wrap;
          background: #ccc;
          box-shadow: #888 2px 2px 4px;
          border-radius: 10px;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          margin: 0rem 1rem;
          width: 100%;
          height: 100%;

          .battery-slot {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            max-height: 5rem;
            width: 30%;
            margin: 2px;
            flex: 1 1 auto;
            &--expanded {
              display: flex;
            }
            i.icon.indicator {
              display: inline-block !important;
              background: #ccc;
              font-size: 2rem;
              color: #ddd !important;
              //border-radius: 10% !important;

              &.empty {
                background: #111;
                color: orange !important;
              }
              &.charging {
                background: #555;
                color: #444 !important;
              }
              &.ready {
                background: green;
              }
            }
          }
        }
      }

      &.minimized {
        .slot-wrapper {
          margin: 0;
          width: auto;
          display: flex;
          justify-content: flex-end;

          .battery-slots.inline {
            width: 12em;
            .battery-slot {
              min-height: 1rem;
              max-height: 2rem;
              width: 10px;
              max-width: 2.5rem;
              //margin-right: 10px;

              i.icon.indicator {
                margin-right: 4px;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

//.ui.column.wrapper--temperatures {}
.ui.modal.visible.active.create-region {
  form {
    margin: 10px 20px 20px;
  }
}
