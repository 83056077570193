/* SplashScreen.css */
.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    color: #ffffff;
    animation: fadeOut .5s ease-out;
    opacity: 1;
  }
  
  // .loader {
  //   border: 16px solid #f3f3f3; /* Light grey */
  //   border-top: 16px solid #3498db; /* Blue */
  //   border-radius: 50%;
  //   width: 120px;
  //   height: 120px;
  //   animation: spin 2s linear infinite;
  // }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .splash-screen-main-content {
    padding: 20px;
    text-align: center;
    animation: fadeIn .1s ease-in;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    50% {
      opacity: 0;
    }
  }
  
  /* Responsive */
  @media (max-width: 600px) {
    .splash-screen h1 {
      font-size: 1.5rem;
    }
  
    .loader {
      width: 80px;
      height: 80px;
    }
  }
  