@import "./../../assets/styles/colors.scss";

.screen-wrapper--new-layout {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  .main-header {
    display: flex;
    justify-content: space-between;
    height: 56px;
    font-size: 1rem;
    // position: fixed;
    width: 100%;
    z-index: 999;
    .left-side {
      max-width: 250px;
      width: 14%;
      background: $primaryColor;
      color: #eee;
      font-size: 1rem;
      position: relative;
      padding-right: 10px;

      .flex {
        height: 56px;
        display: flex;
        align-items: center;
      }
      button.burger-menu {
        background: none;
        color: #eee;
        font-size: 1.5rem;
      }

      .h3.txt-logo {
        margin-top: 5px;
        font-size: 1.2rem;
        font-weight: 300;
        .bold {
          font-weight: 600;
        }
        a {
          color: #eee;
        }
      }
      .burger-wrapper {
        z-index: 9999;
      }
      .nav-menu {
        position: absolute;
        padding: 0px;
        top: 56px;
        width: 100%;
        border-radius: 0 0 20px 0;
        z-index: 99999999999;
        background: $primaryColor;
        aside nav {
          .ui.menu {
            width: 100%;
            background: none;
            .item {
              font-size: 1rem;
              font-weight: 900;
              width: 100%;
              a {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
              }
            }
            .item:hover {
              background-color: #fff;
              a {
                color: #444;
              }
            }
          }
        }
      }
    }
    #header-portal {
      padding: 5px 10px;
      flex: 1;
      width: 70%;
      position: static;
      .ui.button {
        background: $primaryColor;
        &,
        i {
          color: $white;
        }
      }
      header {
        height: 100%;
        display: flex;
        justify-content: space-between;
        .nick-name {
          color: #333;
          font-size: 1rem;
          display: flex;
          align-items: center;
          margin-right: 1em;
        }
        .flex {
          display: flex;
          height: 100%;
          align-items: center;
          .h3 {
            font-size: 1rem;
            font-weight: 400;
            &.sp-serial-number {
            }
            &.sub-path {
              color: #666;
            }
          }
        }
      }
    }
    .nav-wrapper {
      padding: 0px 10px;
      flex: 1;
      display: flex;
      max-width: 15%;
      z-index: 999;
      font-size: 30px;
      font-size: 1rem;
      align-items: center;
      justify-content: flex-end;
      .nav--login {
        & > .ui.menu {
          box-shadow: none;
        }
      }
      .ui.menu {
        border: none;
        font-size: 1rem;
        background: none;
        justify-content: flex-end;
        &:hover {
          background: none;
        }
        span.small {
          font-size: 0.8rem;
          color: black;
        }
        .menu.sub-menu {
          left: -50px;
          width: 60px;
          min-width: 60px;
          .item {
            width: 100%;
          }
        }
        .ui.item.dropdown {
          &:before {
            // hides kind of divider
            background: none;
          }
        }
      }
    }
  }

  #header-portal {
    * {
      color: $primaryColor2;
    }
  }
}

.main-content {
  flex-grow: 1; /* Takes up the remaining space */
  overflow-y: auto; /* Make the content scrollable */
  padding: 20px; /* Optional padding for spacing */
  background-color: #f5f5f5;
}

// .marginlefting {
//   margin-left: 60px !important;
// }

// .displaynone {
//   display: none !important;
// }

// .displayblock {
//   display: block !important;
// }

// .sidebar .item i {
//   font-size: 24px;
//   margin-top: -5px !important;
// }

// .logo {
//   height: 48px !important;
//   padding: 10px !important;
// }

// .logo img {
//   width: 100% !important;
//   height: 38px !important;
// }

// .title.item {
//   padding: 0.92857143em 1.14285714em !important;
// }

// .dropdown .menu .header {
//   padding-top: 3.9px !important;
//   padding-bottom: 3.9px !important;
// }

.main-header {
  display: flex;
  justify-content: space-between;
  height: 56px;
  font-size: 1rem;
  // position: fixed;
  width: 100%;
  z-index: 999;
  .left-side {
    max-width: 250px;
    z-index: 9999999;
    width: 14%;
    background: $primaryColor;
    color: #eee;
    font-size: 1rem;
    position: relative;
    padding-right: 10px;

    .flex {
      height: 56px;
      display: flex;
      align-items: center;
    }
    button.burger-menu {
      background: none;
      color: #eee;
      font-size: 1.5rem;
    }

    .h3.txt-logo {
      margin-top: 5px;
      font-size: 1.2rem;
      font-weight: 300;
      .bold {
        font-weight: 600;
      }
      a {
        color: #eee;
      }
    }
    .burger-wrapper {
      z-index: 9999;
    }
    .nav-menu {
      position: absolute;
      padding: 0px;
      top: 56px;
      overflow: hidden;
      width: 100%;
      border-radius: 0 0 10px 0;
      z-index: 999999999999999 !important;
      background: $primaryColor;
      aside nav {
        z-index: 999999999999999 !important;
        .ui.menu {
          width: 100%;
          background: none;
          z-index: 999999999999999 !important;

          .item {
            font-size: 1rem;
            font-weight: 900;
            width: 100%;
            a {
              width: 100%;
              display: inline-flex;
              justify-content: space-between;
            }
          }
          .item:hover {
            background-color: #fff;
            border-radius: 0px;
            a {
              color: #444;
            }
          }
        }
      }
    }
  }
  #header-portal {
    padding: 5px 10px;
    flex: 1;
    width: 70%;
    position: static;
    .ui.button {
      background: $primaryColor;
      &,
      i {
        color: $white;
      }
    }
    header {
      height: 100%;
      display: flex;
      justify-content: space-between;
      .nick-name {
        color: #333;
        font-size: 1rem;
        display: flex;
        align-items: center;
        margin-right: 1em;
      }
      .flex {
        display: flex;
        height: 100%;
        align-items: center;
        .h3 {
          font-size: 1rem;
          font-weight: 400;
          &.sp-serial-number {
          }
          &.sub-path {
            color: #666;
          }
        }
      }
    }
  }
  .nav-wrapper {
    padding: 0px 10px;
    flex: 1;
    display: flex;
    max-width: 15%;
    z-index: 999;
    font-size: 30px;
    font-size: 1rem;
    align-items: center;
    justify-content: flex-end;
    .nav--login {
      & > .ui.menu {
        box-shadow: none;
      }
    }
    .ui.menu {
      border: none;
      font-size: 1rem;
      background: none;
      justify-content: flex-end;
      &:hover {
        background: none;
      }
      span.small {
        font-size: 0.8rem;
        color: black;
      }
      .menu.sub-menu {
        left: -50px;
        width: 60px;
        min-width: 60px;
        .item {
          width: 100%;
        }
      }
      .ui.item.dropdown {
        &:before {
          // hides kind of divider
          background: none;
        }
      }
    }
  }
}
