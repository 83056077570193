@import "./../../../assets/styles/colors.scss";

.icon.control.control--show-all {
  width: 40px;
  height: 40px;
  background: white;
  &:hover {
    box-shadow: 2px 2px 4px 2px rgba(0, 100, 50, 0.5);
  }
}
.leaflet-marker-icon {
  background: none;
  border: none;
}
.marker-cluster {
  color: black;
}

//style for leaflet markers
.leaflet-marker-pane {
  //z-index: 1999;
}
.ui.button.fly-to-button {
  z-index: 1;
  background: $white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  &:hover {
    background: #ccc;
  }
  svg {
    flex: 2;
    width: 40px;
    height: 40px;
  }
}
.leaflet-popup.sharingPoints {
  div.leaflet-popup-content-wrapper {
    position: relative;
    padding: 0px !important;
    background: #f9f9fa;
    color: #fff !important;
    min-height: 60px;
    margin-left: -5px;
    margin-top: 2px;
    .leaflet-popup-content {
      padding:0px;
      width: 350px;
      background: #f9f9fa;
      color: #fff !important;
      margin: 0 0 0 0px;
      padding: 0px !important;
      border-radius: 10px;
      
      .popup-content {
        .sp-name > h4 > a:hover {
          color: #aaa;
        }
        * {
          color: $lightGrey;
        }
        .row {
          display: flex;
          justify-content: space-between;
        }
        .image-wrapper {
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
          display: flex;
          align-items: center;
          .iconImg {
            width: 40px;
            height: 60px;
            margin-left: -19px;
            //margin-top: 12px;
          }
        }
        .footer {
          margin-top: 5px;
        }
      }
    }
  }
  div.leaflet-popup-tip-container {
    display: none;
  }
  a.leaflet-popup-close-button {
    display: none;
  }
}
